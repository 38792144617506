import React from 'react'
import Layout from '../../components/layout/Layout'
import { SeriesPage } from '../../components/series/SeriesPage'

const TypeScriptTypesDeepDiveSeries = () => {
  const name = 'typescript-types-deep-dive'
  const title = 'TypeScript types deep dive'
  const subtitle = 'Unearth the mysteries of TypeScript type system'
  const image = '/images/typescript-types-deep-dive.jpg'
  const imageAlt =
    'The TypeScript logo with the "Types Deep Dive" sentence underneath using a pixelated font. Very nerdy.'

  return (
    <Layout>
      <SeriesPage
        name={name}
        title={title}
        subtitle={subtitle}
        image={image}
        imageAlt={imageAlt}
      >
        <p>
          TypeScript's type system is extremely powerful. It can also be really
          hard to wrap your head around.
        </p>
        <p>
          This series takes you by the hand from the very basic foundations you
          need to understand TypeScript's type system to its most advanced
          features.
        </p>
        <p>
          If you enjoy watching YouTube programming videos, you can also{' '}
          <a
            href="https://www.youtube.com/watch?v=ThLVga4oKRw&list=PLWkmatwYW3nEPR6MuD6oYkuqILuHUH5oq"
            target="_blank"
          >
            find a complement to these articles in video format
          </a>
        </p>
      </SeriesPage>
    </Layout>
  )
}

export default TypeScriptTypesDeepDiveSeries
